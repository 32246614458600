import VueRouter from 'vue-router'
import {routes} from './routes';
import store from '../store/index'
import {rolesList} from "../enums/enums";

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: function () {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({x: 0, y: 0})
            }, 500)
        })
    },
    routes
})

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth === false)) {
        return next();
    }

    if (to.matched.some(record => record.meta.requiresAuth)) { // нужна авторизация
        if (store.getters.IS_LOGGED_IN) {
            next()
        } else {
            next('/login')
        }
    }

    if (to.matched.some(record => record.meta.guest)) {
        if (store.getters.GET_TOKEN) {
            next({name: 'Home'})
        } else {
            next()
        }
    }
    //Проверка пермишенов
    if(to.meta.access) {
        if(to.meta.access && store.getters.GET_USER.currentPermissions && store.getters.GET_USER.currentPermissions.indexOf(to.meta.access) != -1) {
            next();
        } else {
            next('/');
        }
    }
    //TODO: думаю это можно будет отправить в мусорку
    if (to.matched.some(record => record.meta.isAdmin)) { // если требуются права админа

        if (store.getters.GET_USER.currentPermissions && store.getters.GET_USER.currentPermissions.indexOf('admin_panel.view') != -1) {
            next()
        } else {
            next({name: 'Home'})
        }
    } else { // в кабинете
        if (store.getters.GET_USER.currentPermissions && store.getters.GET_USER.currentPermissions.indexOf('admin_panel.view') != -1) {
            next()
        } else {
            next('/login')
        }
    }


})

export {router}
